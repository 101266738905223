$shared-header-height: 60px;
$navigation-stack-height: 35px;
$page-header-height: 80px;

$header-font-size: 24px;
$prefered-bold-weight: 600;

// * Note: `$settings-header-font-size` may be a size that will be used on all
// * the pages (because of the design refresh). Not clear for now.
// * @TODO rename if used on other "non-settings" pages.
$settings-header-font-size: 32px;

$settings-nav-top-offset: 112px;
$settings-nav-width: 300px;
$settings-nav-font-size: 18px;
$settings-content-width: 640px;
$settings-heading-options-font-size: 14px;

$page_width: 1200px;
$break-small: 320px;
$break-medium: 640px;

$xsmall-size: 4px;
$small-size: 8px;
$small-medium-size: 12px;
$medium-size: 16px;
$medium-large-size: 24px;
$large-size: 32px;
$sxlarge-size: 48px;
$xlarge-size: 64px;

$margin-bottom-xmedium: 32px;
$margin-bottom-large: 40px;
$margin-bottom-xlarge: 64px;
