@use 'sass:color';

$purple-arbnco: rgba(145, 49, 168, 1);
$purple-arbnco-lighter: rgba(184, 119, 235, 1);
$purple-arbnco-darker: rgba(75, 0, 234, 1);
$orange-arbnco: rgba(248, 106, 12, 1);
$faded-black: #313131;
$faded-black-80: #3A3A3A;
// these are going to be replaced with new purple arbnco
$darkish-purple: #3b2748;
$purple-arbnwell: #3b2748;
$purple-arbnwell-half: rgba(107, 36, 122, 0.5);
$purple-arbnwell-quarter: rgba(107, 36, 122, 0.25);
$shadow_darkish-purple04: rgba(94, 22, 110, 0.1);
$purple-arbnwell-light: #4b2166;
$warning-yellow: #e3b45c;
$alert-red: #e35b5c;
$pass-green: #77c884;
$offline-gray: #e3e3e3;

$purple-faded: #dfd1e2;
$dark-gray-faded: #949494;
$secondary-gray-400: #E9EDF7;

$alert-warning-icon: #f6b765;
$alert-red-icon: #ef5c5c;
$alert-red-tickets-modal: #ff0067;

$alert-sensivity-warning: #fda531;
$alert-sensivity-danger: #ef5c5c;
$alert-sensivity-off: #494949;

$dark-black: #222222;
$dark-black2: #1d1d1d;
$pale-grey: #f7f8fa;
$pale-grey-active: #f0f1f3;
$pale-grey-half: rgba(247, 248, 250, 0.5);
$pale-grey-75: rgba(247, 248, 250, 0.75);
$brown-gray: #8b8b8b;
$dark-gray: #404040;
$dark-gray2: #535353;
$dark-gray3: #737373;
$light-gray: #d1d1d1;
$faded-gray: #cfcfcf;
$mid-gray: #e9e9ea;

$border-gray-dark: #d8d8d8;
$border-gray: #e7e7e7;
$hover-gray: #fbfcfd;

$text-color-black: $dark-gray;

$main-background: #ffff;
$table-background-pale-grey: #f5f5f5;
$table-item-underline: #e0e0e0;
$text-on-purple-arbnwell: #f7f6f8;

$tri-colour-good: #00b08a;
$tri-colour-average: $alert-warning-icon;
$tri-colour-bad: #f4298b;

$background-grey: #f5f6f8;

$error: var(--alert);
// floor plan sensors' icon colours
$sensor-online: #75e2aa;
$sensor-offline: $faded-black-80;
$sensor-pass: #67e4a9;
$sensor-fail-low: #66fff2;
$sensor-fail-high: #ff5959;
$sensor-warning-low: #66f1cd;
$sensor-warning-high: $alert-warning-icon;

$button-group-selected: #f5f5f5;
$button-group-border: #d4d4d4;

$status-processing: $faded-black;

$preview-csv-container: #c4c4c4;

@mixin circular-radial-gradient($color: #515151) {
  background: radial-gradient(circle, #515151 4.5%, #1d1d1d 100%);
  
  @if $color != #515151 {
    background: radial-gradient(circle, $color 0%, color.scale($color, $alpha: -65%) 80%);
  }
}
