@import 'src/app/shared/sizings';
@import 'src/app/shared/colors.scss';
// used to apply the base calendar styles
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '@angular/cdk/overlay-prebuilt.css';

@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';

* {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  color: $dark-gray;
  background-color: $pale-grey;
  display: inline-block;
}

// Override arbn-components css variables.
body {
  --primary: #{$purple-arbnwell};
  --primaryContrast: #ffffff;
  --primaryLighter: #{$purple-arbnwell-half};
  --primaryLightest: #{$purple-arbnwell-quarter};
  --primaryDarker: #{$darkish-purple};
  --primaryDarkest: #{$shadow_darkish-purple04};
  --primaryDisabled: #{$purple-faded};
}

::ng-deep [arbn-button] {
  width: min-content;
  white-space: nowrap;
}

::ng-deep .arbn-button-naked {
  text-decoration: none !important;
}

::ng-deep .mat-dialog-container {
  color: $dark-gray !important;
}

::ng-deep arbn-form-field.field-with-tips {
  .arbn-form-field-upper {
    position: relative;
  }

  .arbn-field-tips-container {
    position: absolute;
    right: 1em;
  }
}

.password_tips_menu {
  background: white;
  border-radius: $small-size;
  padding: $medium-size;
  font-size: 0.9em;

  ul {
    list-style-position: inside;
  }
}

.vertical-layout {
  grid-auto-columns: max-content;
  width: 100%;
  display: grid;
  grid-column-gap: 20px;
  align-items: center;

  &.two-column {
    grid-template-columns: auto auto 1fr;
  }
}

.horizontal-layout {
  position: relative;
  grid-auto-rows: max-content;
  width: 100%;
  display: grid;
  grid-row-gap: 16px;

  > *:not(:last-child) {
    border-bottom: 1px solid #e8e8e8;
  }

  &.no-seperator-line {
    > *:not(:last-child) {
      border-bottom: none !important;
    }
  }

  &.no-gap {
    grid-row-gap: 0;
  }

  &.small-gap {
    grid-row-gap: $xsmall-size;
  }

  &.medium-gap {
    grid-row-gap: $small-size;
  }

  &.large-gap {
    grid-row-gap: $large-size;
  }

  &.small-bottom-padding {
    > * {
      padding-bottom: $xsmall-size;
    }
  }

  &.medium-bottom-padding {
    > * {
      padding-bottom: $small-size;
    }
  }

  &.large-bottom-padding {
    > * {
      padding-bottom: $small-medium-size;
    }
  }
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-horizontal {
  display: flex;
  flex-direction: row;
}

a {
  &:not(.hl) {
    text-decoration: none;
    color: inherit;
  }
  &.hl {
    font-weight: $prefered-bold-weight;
  }
  color: $purple-arbnwell;
}

.mat_dialog_container {
  .content_container {
    width: 100%;
  }
}

.page_container {
  overflow-y: overlay;
  background: $pale-grey;
  position: absolute;
  top: $shared-header-height + $navigation-stack-height;
  bottom: 0;
  left: 0;
  right: 0;
}

.content_container {
  position: relative;

  display: flex;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 36px auto 0;
  width: 100%;
  max-width: $page_width;
  //padding:24px;
  &.outer {
    width: 90%;
  }

  &:last-child {
    margin-bottom: 60px;
  }

  &.no-padding {
    padding: 0;
  }

  &.no-margin {
    margin: 0 auto;
  }

  &.no-overflow {
    overflow: hidden;
  }

  &.white-bg {
    background: white;
  }

  &.flush-top {
    margin-top: 0;
  }

  &.border {
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
  }
}

.center-text {
  text-align: center;
}

.grid_container {
  text-align: center;

  * {
    text-align: left;
    margin: 0 auto;
  }

  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  @media screen and (max-width: $break-medium) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: $break-small) {
    grid-template-columns: 1fr;
  }
}

.split_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

:focus {
  outline: none;
}

::ng-deep .mat-calendar {
  button {
    padding: 0;
  }
}

::ng-deep .mat-form-field-infix {
  width: fit-content !important;
}

::ng-deep .mat-date-range-input-container {
  justify-content: space-between;
}

.mat-calendar-body-cell {
  transition: 0.33s background-color ease-in-out;
}

.mat-calendar-body-disabled {
  cursor: not-allowed !important;
}

.mat-datepicker-toggle-active {
  color: $purple-arbnwell !important;
}
.between_date.mat-calendar-body-disabled {
  background: $pale-grey-active !important;

  div {
    border-top: 1px;
    border-radius: 0;
    border-bottom: 1px;
    border-right: 0;
    border-left: 0;
    border-style: solid;
    border-color: $purple-arbnwell;
    color: $light-gray !important;
    background: transparent !important;
  }
}

.between_date,
.to_date,
.from_date,
.same_day {
  background: $purple-arbnwell;

  > div {
    background: transparent !important;
    color: white !important;
    border: none;
  }
}

.disabled_date {
  background: $pale-grey-active !important;

  div {
    color: $light-gray !important;
    background: transparent !important;
  }
}

.blank_date {
  background: transparent !important;

  div {
    color: $dark-black !important;
    background: transparent !important;
  }
}

.between_date {
  //opacity: 0.8;
  background: $purple-faded;

  div {
    color: $dark-black !important;
  }
}

.from_date {
  border-bottom-left-radius: 10%;
  border-top-left-radius: 10%;
}

.to_date {
  border-bottom-right-radius: 10%;
  border-top-right-radius: 10%;
}

.same_day {
  border-radius: 10%;
}

.hovered {
  background: #e1e1e1;
}

.purple {
  color: $purple-arbnwell;
}

.dark_background {
  background: $dark-black;
}

.white_text {
  color: white;
}

.bold {
  font-weight: $prefered-bold-weight;
}

button,
select,
.button {
  cursor: pointer;
  user-select: none;
  padding: $small-size $large-size;
  border-radius: 4px;
  border: 1px solid $dark-gray;
  background: transparent;

  &.plain {
    border: none;
    box-shadow: none;
    text-align: left;
  }
  &.purple-text {
    color: $purple-arbnwell;
  }

  &.dark {
    background: $purple-arbnwell;
    color: white;
  }

  &.light {
    background: $purple-arbnwell-light;
    color: white;
  }

  &.purple {
    border-color: $purple-arbnwell;
    color: $purple-arbnwell;
    background: transparent;
  }

  &.error {
    border: 3px solid $alert-red;
  }
}

// `.custom` class is used to ignore this global styles.
input[type='text'],
input[type='password'],
.input_style,
input[type='number'],
select {
  &:not(.custom) {
    &[readonly],
    &.readonly {
      background: rgb(25 25 25 / 5%);
      cursor: not-allowed;
    }
    position: relative;
    padding: 10px;
    max-width: 100%;
    border-radius: 4px;
    border: solid 1px $light-gray;
    box-sizing: border-box;
    color: $dark-gray;
  }
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.disabled,
:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.loading {
  cursor: progress !important;
}

.padding-first-container {
  padding: $xlarge-size;
}

.padding-first-container-slim {
  padding: $xlarge-size $small-size;
}

.padding-xlarge {
  padding: $xlarge-size;
}

.padding-large {
  padding: $large-size;
}

.padding-medium {
  padding: $medium-size;
}

.padding-medium-large {
  padding: $medium-size $large-size;
}

.padding-none-none-medium-none {
  padding: 0 0 $medium-size 0;
}

h1 {
  font-weight: $prefered-bold-weight;
  font-size: $header-font-size;
  margin: -7px -1px -6px 0;
}

h2 {
  font-weight: $prefered-bold-weight;
  font-size: $header-font-size - 4px;
  margin: -6px -1px -5px 0;
}

h3 {
  font-weight: $prefered-bold-weight;
  font-size: $header-font-size - 8px;
  margin: -5px -1px -4px 0;
}

h4 {
  font-weight: $prefered-bold-weight;
  font-size: $header-font-size - 12px;
  margin: -4px -1px -3px 0;
}

.bold {
  font-weight: $prefered-bold-weight + 100;
}

.non-bold {
  font-weight: normal;
}

.margin-bottom-small {
  margin-bottom: $small-size;
}

.margin-bottom-medium {
  margin-bottom: $medium-size;
}

.margin-bottom-xmedium {
  margin-bottom: $margin-bottom-xmedium;
}

.margin-bottom-large {
  margin-bottom: $margin-bottom-large;
}

.margin-bottom-xlarge {
  margin-bottom: $margin-bottom-xlarge;
}

.mat-dialog-container {
  //overflow-y: overlay !important;
  position: relative !important;
  max-height: 95vh !important;
  max-width: 95vw !important;
  padding: 0 !important;

  .exit {
    padding: 16px;
    color: $purple-arbnwell;
    position: absolute;
    top: 0;
    right: 8px;
  }

  .back {
    padding: 16px;
    color: $purple-arbnwell;
    position: absolute;
    top: 0;
    left: 8px;
  }

  &::-webkit-scrollbar {
    width: 1%;
    min-width: $small-size;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $brown-gray;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    opacity: 0.8;
  }
}

::ng-deep .mat-menu-panel {
  overflow: unset !important;
}

.button_container {
  display: grid;
  grid-column-gap: 8px;

  &.col_2 {
    grid-template-columns: auto auto 1fr;
  }
}

::ng-deep {
  .mat-slide-toggle {
    height: auto !important;
  }

  .mat-slide-toggle-thumb-container {
    width: 10px !important;
    height: 10px !important;
    top: 2px !important;
    left: 2px !important;
  }

  .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(18px, 0, 0) !important;
  }

  .mat-slide-toggle-thumb {
    width: 100% !important;
    height: 100% !important;
  }

  .mat-slide-toggle .mat-slide-toggle-ripple {
    display: none;
  }

  .mat-slide-toggle-bar {
    width: 32px !important;
  }

  &.purple {
    &.mat-checked .mat-slide-toggle-thumb {
      background: white;
    }

    &.mat-checked .mat-slide-toggle-bar {
      background: $purple-arbnwell;
    }
  }

  &.gray-purple {
    &.mat-checked {
      .mat-slide-toggle-thumb {
        background: $purple-arbnwell !important;
      }

      .mat-slide-toggle-bar {
        background: white;
      }
    }

    .mat-slide-toggle-thumb {
      background: $brown-gray;
    }

    .mat-slide-toggle-bar {
      background: white;
    }
  }
}

::ng-deep .wellLargeTooltip {
  font-size: 16px;
  background: $dark-gray;
  padding: $medium-size !important;
  overflow: visible;
}
::ng-deep .wellTooltip {
  background: $dark-gray;
  overflow: visible;
}

hr {
  border: 3px solid $dark-gray !important;
  border-radius: 12px;
  opacity: 0.66;
}

hr.mat-card-separator {
  border: none !important;
  border-bottom: 1px solid $secondary-gray-400 !important;
  border-radius: 0;
  opacity: 1;
}

.loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-color: #eceaea;
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.4;
  filter: alpha(opacity=40);
}

::ng-deep .mat-form-field {
  .mat-form-field-infix {
    border-top: 0 !important;
  }

  .mat-form-field-outline {
    top: 0 !important;
  }

  .mat-form-field-outline-thick > * {
    border-width: 1px !important;
    color: var(--primary);
  }
}

// Customize `arbn-slide-toggle`.
::ng-deep .arbn-slide-toggle-inner-layout {
  .arbn-layout-slide-toggle {
    &:before {
      background-color: var(--primary) !important;
    }

    &:after {
      background-color: var(--primaryContrast) !important;
    }
  }

  input[type='checkbox']:not(:checked) + .arbn-layout-slide-toggle {
    &:before {
      background-color: var(--primaryContrast) !important;
      border: 1px solid var(--primary) !important;
      box-sizing: border-box;
    }

    &:after {
      background-color: var(--primary) !important;
    }
  }
}

// customize `arbn-side-menu`
::ng-deep .side-menu-container.open {

  & .side-menu-item {

    &.active-item, &.show-highlight:hover {
      // high constrast purple
      color: $purple-arbnco-lighter !important;
      background-color: $faded-black-80 !important;
    }
  }
}

// customize `mat-card`
::ng-deep .mat-card-header {

  .mat-card-header-text {
    margin: 0;

    .mat-card-title {
      font-weight: $prefered-bold-weight;
      color: $darkish-purple;
    }
  }
}

.error {
  color: $error;
}

.form-errors {
  font-size: 0.8em;
}

// Text
.text-tip {
  font-style: italic;
}

// Spaces pages layouts.
.tab-page-title {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: $margin-bottom-large;
}

// Profile/settings Layouts
.page-container {
  width: min-content;
  min-height: 100%;
  margin: 0 auto;
  padding-top: $xlarge-size;
  padding-bottom: $margin-bottom-xlarge;
}

.page-title {
  font-size: 32px !important;
  margin-bottom: $medium-size;
}

.page-content-container {
  width: $settings-content-width;
  max-width: 100%;
  background: white;
  padding: $xlarge-size;
  border-radius: 8px;
}

.page-content-title {
  margin-bottom: $small-size;
}

.mat-divider.page-content-divider,
.page-content-divider {
  margin-bottom: $margin-bottom-large;
}

.page-form {
  max-width: 320px;

  .arbn-form-field-container {
    margin-bottom: $medium-size;
  }

  arbn-radio-group,
  .page-form-field-toggle,
  .page-form-field-select .arbn-form-field-container {
    margin-bottom: $margin-bottom-large;
  }
}

.page-form-fields-container {
  margin-bottom: $margin-bottom-large;
}
